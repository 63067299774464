import { Alert, Platform } from "react-native";

const alertPolyfill = (title, description, options, extra) => {
    const result = window.confirm([title, description].filter(Boolean).join('\n'))

    if (result) {
        const confirmOption = options.find(({ style }) => style !== 'cancel')
        confirmOption && confirmOption.onPress()
    } else {
        const cancelOption = options.find(({ style }) => style === 'cancel')
        cancelOption && cancelOption.onPress()
    }
}

const alert = Platform.OS === 'web' ? alertPolyfill : Alert.alert



export function confirmationDialog(confirm) {
    alert('Bist du sicher?', 'Klick OK um weiterzumachen.', [
        {
          text: 'Abbrechen',
          onPress: () => console.log("Cancelled operation"),
          style: 'cancel',
        },
        {text: 'OK',onPress: confirm},
    ]);
}

