import React, { useContext } from 'react';
import { Button, StyleSheet, Text, TextInput, View, Image, Pressable, Alert } from 'react-native';
import { FontAwesome5 as FontAwesome } from '@expo/vector-icons';
import { Identity, transparentWhite, urlFromUpload } from './constants.js';
import { Modal } from 'react-native-web';

export function interpret_cost_object(me_id, cost) {
    let amt = Number.parseFloat(cost.amount);
    let repayment_fract = (cost.nr_unpaid_shares / cost.nr_shares);
    let repayment = repayment_fract * amt;
    let single_payment = amt / cost.nr_shares;

    let my_gain = 0;

    let my_share_paid = cost.my_share.paid == true || cost.my_share.paid == null;
    let am_creditor = me_id === cost.creditor_id;
    if( am_creditor ) {
      my_gain += repayment;
    } else {
      my_gain -= my_share_paid ? 0 : single_payment;
    }

    return {my_gain, single_payment, amt, cost, am_creditor}
}

export function MoneyCard(props) {
    let cost = props.cost;
    let [login,setLogin] = useContext(Identity);
    let {my_gain, single_payment, amt} = interpret_cost_object(login.user.id, cost);

    let date = new Date(cost.added_on).toDateString().substring(4, 10);

    let user = { name: "Irgendwer" };
    if ( props.users && props.users[cost.creditor_id] ) {
        user = props.users[cost.creditor_id];
    }

    /* 
        Zurückgezahlt wird insgesamt: {repayment.toFixed(2)}€{"\n"}
        Jeder Schuldner zahlt: {single_payment.toFixed(2)}€{"\n"}
        {cost.nr_shares} Beteiligte*/

    return (
        <Pressable style={{...s.card, marginLeft:10, padding: 8, borderRadius: 20, ...props.style}} onPress={props.onPress}>
            
            <View style={{...s.cardBody, paddingBottom: 0}}>
                <Text style={{fontSize: 16, fontWeight: 'bold'}}>{cost.name}</Text>
                <View style={{flexDirection: "row", alignItems: "center"}}>
                    <RoundImage style={{marginLeft: -25}} imgSize={30} imgUrl={urlFromUpload(user.profile_pic)}/>
                    <Text style={{color: "#6d6d6d"}}>   {user.name} zahlte: {amt.toFixed(2)}€</Text>
                </View>
            </View>
            <View>
                <Betrag amount={my_gain} isWhite/>
                <Text style={{textAlign:'right', color: "#6d6d6d"}}>{date}</Text>
            </View>
        </Pressable>
    );
}

export function UserMoneyCard(props) {
    var user = props.user ||{};
    var stat = props.stat;

    let to_recieve = Number.parseFloat(stat.to_recieve);
    let to_pay = Number.parseFloat(stat.to_pay);

    return (
        <Card {...props} imgUrl={urlFromUpload(user.profile_pic)} imgSize={50}
        title={<>
            <Text numberOfLines={1}>{user.name}</Text>
        </>}
        text={<View style={{flexDirection:"row"}}><Betrag amount={to_recieve} isWhite/><Text> + </Text><Betrag amount={-to_pay} isWhite/></View>}
        >
            <View style={{marginTop: 0, marginRight: 10}}><Text>total:</Text><Betrag amount={to_recieve-to_pay} isWhite/></View>
        </Card>
    )
}

export function Betrag(props) {
    let amount = props.amount.toFixed(2);
    let negative = amount.startsWith("-")
    let isnull = amount=="0.00"
    if( !negative&&!isnull ) {
        amount = "+"+amount;
    }

    return (<Text style={{
        color:negative?"#fc5b05":"#00e04e", fontSize: 18,
        fontWeight: "bold", textAlign: "center", backgroundColor: props.isWhite?"#ffffff":"#f4f4f4", borderColor: props.isWhite?"#f4f4f4":"#eaeaea", 
        borderWidth: 2, minWidth: 70,
        borderRadius: 10, padding : 2, elevation: 1,
        ...(props.strikethrough||isnull?{textDecorationLine: 'line-through', color: "grey"}:{})
    }}>{amount}<Text style={{color:"#c1c1c1", fontWeight:"normal", fontSize: 10}}>€</Text></Text>)
}

export function Card(props) {
    var imgSize = props.imgSize || 65;
    return (
        <Pressable style={{...s.card, ...props.style}} onPress={props.onPress}>

            <RoundImage style={s.cardImgC} imgSize={imgSize} imgUrl={props.imgUrl}>
                {props.icon && <View style={s.cardImgIcons}>{props.icon}</View>}
            </RoundImage>
            
            <View style={s.cardBody}>
                <Text style={s.cardTitle}>{props.title}</Text>
                <Text style={s.cardDesc}>{props.text}</Text>
            </View>

            {props.children}
        </Pressable>
    )
}

export function UserCard(props) {
    var user = props.user ||{};

    return (
        <Card {...props} text={user.bio} imgUrl={urlFromUpload(user.profile_pic)}
        title={<>
            <Text numberOfLines={1}>{user.name}</Text>
            <Text style={{fontSize: 15, color: "rgba(0, 0, 0, 0.7)"}}>{"\n"}@{user.username}</Text>
        </>}></Card>
    )
}

export function ReviewCard(props) {
    var user = props.user ||{};
    const critColor = "rgba(255, 0, 0, 0.78)";
    const posColor = "rgba(0, 205, 14, 0.79)";
    const icon = <FontAwesome size={19} color={props.critical?critColor:posColor} name={props.critical?"heart-broken":"heart"} solid/>;

    return (
        <Card {...props} icon={icon} imgUrl={user.avatarUrl}
        title={<>
            {props.critical?
                <Text style={{color:critColor, fontWeight: 'normal'}}>Nicht empfohlen</Text>:
                <Text style={{color:posColor, fontWeight: 'normal'}} >Empfohlen</Text>}
            <Text numberOfLines={1}>{"\n"+props.title}</Text>
            <Text style={{fontSize: 15, color: "rgba(0, 0, 0, 0.7)"}}>{"\n"}{user.name} <Text style={{color: "rgba(0, 0, 0, 0.48)"}}>- {user.username}</Text> </Text>
        </>}></Card>
    )
}

export function ReplyCard(props) {
    var user = props.user ||{};
    var icon;
    var flag;
    var representing = props.representing ||{};
    if(props.official) {
        flag = <Text style={{color:"rgba(0, 114, 255, 1)", fontWeight: 'normal'}}>Offizielle Antwort{"\n"}</Text>;
        icon = <FontAwesome size={19} color={"rgba(0, 114, 255, 1)"} name="star" solid/>;
    } else if (representing.name) {
        flag = <Text style={{color:"rgba(255, 157, 0, 1)", fontWeight: 'normal'}}>Laden: {representing.name}{"\n"}</Text>;
        icon = <FontAwesome size={19} color={"rgba(255, 157, 0, 1)"} name="flag" solid/>;
    }

    return (
    <View style={{flexDirection: "row"}}>
        <View style={{width: 40, justifyContent: 'center'}}>
            <Text style={{fontSize:20, textAlignVertical:"center"}}> =&gt;</Text>
        </View>
        <Card {...props} style={{flex: 1}} imgUrl={user.avatarUrl}
            title={<>
                {flag}
                <Text style={{fontSize: 15, color: "rgba(0, 0, 0, 0.7)"}}>{user.name} <Text style={{color: "rgba(0, 0, 0, 0.48)"}}>- {user.username}</Text> </Text></>}
            imgSize={50}
            icon={icon}
        ></Card>
    </View>);
}

export function News(props) {
    var user = props.user ||{};
    return (
    <View style={{borderTopWidth: 5, borderColor: "black", marginBottom: 20}}>
        <Text style={{fontSize: 20, fontWeight: "bold"}}>{props.title}</Text>
        <Text style={{fontSize: 15, color: "rgba(0, 0, 0, 0.7)", fontStyle: "italic"}}>by {user.name} <Text style={{color: "rgba(0, 0, 0, 0.48)"}}>- {user.username}</Text> </Text>
        <Text style={{marginTop: 20, fontSize: 17}}>{props.text}</Text>
    </View>
    );
}

export function RoundImage(props) {
    var imgSize = props.imgSize || 65;

    return (
        <View style={{...props.style, width: imgSize, height: imgSize }}>
            <View style={{...s.cardImgClip, ...props.styleRing}}>
                <Image source={{uri: props.imgUrl }} // || "https://www.100ita.com/blog/wp-content/uploads/2015/10/Bread-5.jpg" 
                height={imgSize} style={{flex: 1}}/>
            </View>
            {props.children}
        </View>
    );
}

const s = StyleSheet.create({
    card: {
        borderColor: "transparent",
        borderRadius: 75/2,
       // maxHeight: 200,
        flexDirection: "row",
        backgroundColor: transparentWhite,
        marginTop: 5,
        marginLeft: 20,
    },
    cardBody: {
        flex: 1,
        padding: 10,
        paddingTop: 5
        
    },
    cardTitle: {
        fontSize: 18,
        fontWeight: 'bold'
    },
    cardDesc: {
        fontSize: 17,
        color: "#222",
        marginTop: 10
    },
    cardImgC: {     
        width: 65,
        height: 65,
        marginLeft: -20,
        
        shadowOpacity: 1,
        marginTop: 8
    },
    cardImgClip: {
        width: "100%", height: "100%",
        justifyContent:"center",
        backgroundColor: "rgba(41, 50, 135, 1)",
        borderRadius: 100/2,
        overflow: "hidden",
        elevation: 7,
    },
    cardImgIcons: {
        position: 'absolute', 
        borderRadius: 100,
        backgroundColor: "white",
        padding: 3,
        right: 0, 
        top: 0,
        marginTop: -3,
        marginRight: -7,
        aspectRatio: 1,elevation: 10
    }
    
})