import { Button, ImageBackground, ScrollView, StyleSheet, Text, TextInput, View, Pressable } from 'react-native';
import React, { useContext, useEffect, useState } from 'react';
import { lightAccentColor } from './constants';

export default function DateComp(props) { 
    const [date, setDate] = useState(new Date(Date.now()));
    const [mode, setMode] = useState('date');
    const [show, setShow] = useState(false);

    const onChange = (event, mode) => {
        let value = event.target.value;
        let parts = value.split(/[^1234567890]/g);
        console.log(parts);

        if(mode=="date") {    
            setDate((prevDate) => {
                let currentDate = new Date(prevDate);
                if(parts.length>=3)
                    currentDate.setFullYear(Number.parseInt(parts[0]), Number.parseInt(parts[1]), Number.parseInt(parts[2]))

                if (props.onChange) props.onChange(currentDate);
                return currentDate;
            });
        } else {
            setDate((prevDate) => {
                let currentDate =new Date(prevDate);
                if(parts[0]) currentDate.setHours(Number.parseInt(parts[0]))
                if(parts[1]) currentDate.setMinutes(Number.parseInt(parts[1])) 
                if(parts[2]) currentDate.setSeconds(Number.parseInt(parts[2]))

                if (props.onChange) props.onChange(currentDate);
                return currentDate;
            });
        }
    };

    const showMode = (currentMode) => {
        setShow(true);
        setMode(currentMode);
    };

    const showDatepicker = () => {
        showMode('date');
    };

    const showTimepicker = () => {
        showMode('time');
    };

    let timestring = date.toTimeString().split(" ");
    let timetstring = timestring[0] || "lm:ao:yi";
    let timetzstring = timestring[2] || "(UTC bro ka)"
    timestring = timetstring.substring(0, 5) + " " +timetzstring;

    console.log( "render show: "+show+", mode: "+mode+", "+`${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`+ " "+`${date.getHours()}:${date.getMinutes()}` )

    return (
        <View style={s.container}>
            <Text>Datum/Uhrzeit:</Text>
            <View style={s.buttonwrapper}>
                <Pressable onPress={showDatepicker} style={s.datebutton}><Text>{date.toDateString()}</Text></Pressable>
                <Pressable onPress={showTimepicker} style={s.datebutton}><Text>{timestring}</Text></Pressable>
            </View>
            {show && (
                mode=="date"?(
                    React.createElement("input", {type: "date", key: "date", onChange: (ev)=>onChange(ev,mode), value: date.getFullYear().toString().padStart(4, "0")+"-"+date.getMonth().toString().padStart(2, "0")+"-"+date.getDate().toString().padStart(2, "0")} )
                )
                : (
                    React.createElement("input", {type: "time", key: "time", onChange: (ev)=>onChange(ev,mode), value: date.getHours().toString().padStart(2, "0")+":"+date.getMinutes().toString().padStart(2, "0")} )
                )
            )}
        </View>
    );
}

const s = StyleSheet.create({
    buttonwrapper: {
        flexDirection: "row",  
        borderColor: lightAccentColor, 
        borderWidth: 2,
        borderRadius: 10, 
        backgroundColor: "white"
    },

    datebutton: {
        flex: 1,
        paddingVertical: 10,
        borderRightColor: lightAccentColor,
        borderRightWidth: 2,
        alignItems: "center"
    }
})