

export const apiUrl = "https://wg-api.kartonrad.de"
//export const apiUrl = "http://localhost:6969"
//export const transparentWhite = 'rgba(255,255,255,0.9)';

export const transparentWhite = 'rgba(255,255,255,0.8)';
//export const accentColor = '#aa00ad';
//export const mutedAccentColor = '#500051';

export const lightAccentColor = '#12912a';
export const accentColor = '#166b26';
export const mutedAccentColor = '#1d3522';
export const mutedlightAccentColor = '#204227';


// uhhh
import React from 'react';
export function urlFromUpload(upload) {
    if(upload===null) return null;
    if(!upload||!upload.id||!upload.extension)
        return apiUrl+"/public/img/rejection.jpg";
    return apiUrl+"/uploads/"+upload.id+"."+upload.extension;
}

export const Identity = React.createContext(false);
export const WgAndUsers = React.createContext({
    wg: {
        name: "WG",
        description: "Loading...",
        profile_pic: null,
        header_pic: null
    }, 
    users: {}
});
