import React from 'react';
import { Button, Dimensions, Image, ImageBackground, Platform, SegmentedControlIOSComponent, StyleSheet, Text, TextInput, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { useWebData } from './hooks';
import { transparentWhite, apiUrl, urlFromUpload, accentColor } from './constants';
import { RoundImage, UserCard } from './components';

const s = StyleSheet.create({
    storeWrapper: {
        alignSelf: 'flex-start',
        flexDirection: "column",
        alignSelf: "stretch"
    },
    storePanel: {
        backgroundColor: transparentWhite,
        padding: 10,
        paddingVertical: 30
    },
    contactCard: {
        width: 200,
        backgroundColor: transparentWhite,
        borderRadius: 75/2,
        padding: 20,
        margin:10,
        overflow: "hidden"
    },
    newsWrapper: {
        marginTop: 40,
        marginHorizontal: 5
    },
    newsContainer: {
        backgroundColor: transparentWhite,

    },
    headerImg: {
        marginBottom: -30,
        zIndex: 1,
        position: 'absolute',
        bottom:0,
        left: 0
    },
    bannerImg: {
        flex:1, 
        minHeight: 200, width: Dimensions.get("window").width, zIndex:1, backgroundColor: "rgba(41, 50, 135, 1)"
    },
    action_heading: {  
        color: "white", 
        borderRadius: 40, 
        backgroundColor: accentColor, 
        marginBottom:5, 
        textAlign: "center", 
        alignSelf: "center",
        fontSize: 20,
        padding: 5
    }
});

const testData = { 
    "id": 1, 
    "url": "lamako", 
    "name": "LAMAKO Inc.", 
    "description": "Wir bei LAMKAO Inc sind vom Finanzamt persönlich mit der Überwachung von Peter Düsseldorf beauftragt worden.", 
    "profile_pic": { "id": 1, "extension": "jpeg", "original_filename": "signal-2022-10-24-125140_009.jpeg", "size_kb": 186 }, 
    "header_pic": { "id": 2, "extension": "jpeg", "original_filename": "signal-2022-10-24-125140_002.jpeg", "size_kb": 270 } 
};



export function StoreScreen(props) {
    /*props.navigation.addListener("focus", () => {
        props.navigation.dangerouslyGetParent().setOptions({ tabBarVisible: false })
    })
    props.navigation.addListener("blur", () => {
        props.navigation.dangerouslyGetParent().setOptions({ tabBarVisible: true })
    });*/

    // store object is handed over by navigation or fetched over network
    var { wg_url, wg : storeP } = (props.route.params||{});
    
    const [storeW] = useWebData((wg_url&&!storeP)?"/api/wg/"+wg_url
    :(!storeP)?"/api/my_wg":null, {
        name: "WG",
        description: "Loading...",
        profile_pic: null,
        header_pic: null
    }, {
        name: "FEHLER!!",
        description: "Konnte auf diese WG nicht zugreifen!"
    });
    console.log(store);
    const store = storeP || storeW;
    //const store = testData;

    const [users] = useWebData((store&&store.id)?"/api/wg/"+store.id+"/users"
    :(!wg_url&&!storeP)?"/api/my_wg/users":null 
    , [], [{
        name: "FEHLER!",
        username: "Halt",
    }]);

    //"https://www.europlan-online.de/files/6af6c04bf76e70f6a3139b8934ad2fb0.jpg"
    return (
        <ImageBackground
            blurRadius={5}
            source={{ uri: urlFromUpload(store.header_pic)}}
            style={{ flex: 1, justifyContent: "center", alignItems: "center", backgroundColor: "rgba(37, 42, 82, 1)" }}
        >
            <ScrollView style={s.storeWrapper}>
                <ImageBackground source={{ uri: urlFromUpload(store.header_pic) }} style={s.bannerImg} > 
                    <RoundImage imgSize={100} style={s.headerImg} styleRing={{borderColor: transparentWhite, borderWidth: 5}} imgUrl={urlFromUpload(store.profile_pic)}></RoundImage>
                </ImageBackground>
                
                <View style={s.storePanel}>
                    <Text style={{fontWeight: "bold", fontSize: 23}}>{store.name}</Text>
                    <Text style={{fontSize: 17}}>{store.description}</Text>
                </View>


                <View style={{...s.newsWrapper, ...props.style}}>
                    { props.onUserPress?<Text style={s.action_heading}>Profil wählen:</Text>:false}

                    {users.map((newE) => {
                        return (
                            <UserCard user={newE} key={newE.id} onPress={() => props.onUserPress?props.onUserPress(newE):""}/>
                        )
                    })}
                </View>
            </ScrollView>
        </ImageBackground>
    );
}