import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { createStackNavigator } from '@react-navigation/stack';
import { Button, ImageBackground, ScrollView, StyleSheet, Text, TextInput, View, Pressable, Dimensions, ImageSourcePropType, Image, Platform } from 'react-native';
import { Betrag, Card, interpret_cost_object, MoneyCard, RoundImage, SimpleCard, UserCard, UserMoneyCard } from './components';
import { accentColor, apiUrl, Identity, lightAccentColor, mutedAccentColor, mutedlightAccentColor, transparentWhite, urlFromUpload, WgAndUsers } from './constants';
import { useWebData } from './hooks';
import React, { useContext, useEffect, useState } from 'react';
import DateComp from './Date';
import { LineChart } from 'react-native-chart-kit';
import * as ImagePicker from 'expo-image-picker';
import * as Sharing from 'expo-sharing';
import { confirmationDialog } from './util';


const FinanceTab = createMaterialTopTabNavigator();
const FinanceStack = createStackNavigator();

const FinanceScreen = (props) => {
  const {wg, users} = useContext(WgAndUsers);
  const [rerenderKey, setRerenderKey] = useState(0);

  function rerender() {
    setRerenderKey((rerenderKey) => rerenderKey+1);
  }

  return (
    <FinanceStack.Navigator initialRouteName="Finanzen">
      <FinanceStack.Screen name="Finanzen">{(props) => {return <FinanceRootScreen key={rerenderKey} rerenderHandle={rerender} {...props}/>}}</FinanceStack.Screen>
      <FinanceStack.Screen name="NewEntry">
        {(props) => {return <NewEntryScreen rerenderHandle={rerender} {...props}/>}}
      </FinanceStack.Screen>
      <FinanceStack.Screen name ="ViewCost" component={CostDetailScreen}/>
      <FinanceStack.Screen name ="HistoricBalanceCosts" component={FinaceCostsScreen}/>
    </FinanceStack.Navigator>
  )
}

const FinanceRootScreen = (props) => {
  const {wg, users} = useContext(WgAndUsers);
  const [login, setLogin] = useContext(Identity);
  var [stats] = useWebData("/api/my_wg/costs/stats", [], []);

  useEffect ( () => {
    let stat = stats.find((statt) => statt.user_id === login.user.id)
    if ( stat  ) {
      props.navigation.setOptions({
        headerRight: (props) => <View style={{marginRight: 10}}><Betrag amount={stat.to_recieve-stat.to_pay}/></View>
      })
    }
  }, [stats])

  return (
    <ImageBackground
      blurRadius={5}
      source={{ uri: urlFromUpload(wg.header_pic)}}
      style={{ flex: 1, backgroundColor: "rgba(37, 42, 82, 1)" }}
    >
      <FinanceTab.Navigator initialRouteName="Einträge" sceneContainerStyle={{backgroundColor: "transparent"}} screenOptions={{tabBarIndicatorStyle: {backgroundColor: accentColor}}}>
        <FinanceTab.Screen name="Einträge">{(pprops) => {
          return( <FinaceCostsScreen {...pprops} rerenderHandle={props.rerenderHandle} canAddEntry/> );
        }}</FinanceTab.Screen>
        <FinanceTab.Screen name="Balance">{(pprops) => {
          return( <FinanceBalanceScreen {...pprops} rerenderHandle={props.rerenderHandle} users={users} stats={stats}/> );
        }}</FinanceTab.Screen>
        <FinanceTab.Screen name="Stats" component={StatsScreen}/>
      </FinanceTab.Navigator>
    </ImageBackground>
  )
}

const StatsScreen = (props) => {
  var [stats] = useWebData("/api/my_wg/costs/over_time/week", [], []);

  let fakedata=[0, 0];
  let datasets = [
    { key: "i_recieved", data: [], color: (opacity = 1) => `rgba(63, 252, 0, ${opacity})`, withShadow: false},
    { key: "i_paid", data: [], color: (opacity = 1) => `rgba(252, 5, 5, ${Math.min(1,opacity+0.5)})`},
    { key: "wg_spending", data: [], color: (opacity = 1) => `rgba(252, 132, 5,${Math.min(1,opacity+0.5)})`},
    { key: "my_spending", data: [], color: (opacity = 1) => `rgba(89, 78, 252, ${Math.min(1,opacity+0.5)})`},
  ]
  let labels= [];

  let statindex = 0;
  let is_ready= false;
  if( stats[0] && stats[0].week){
    let weeknum = stats[0].week;
    for (let i = 0; i<6; i++) {
      let thisstat = stats[statindex];
      if ( thisstat && thisstat.week === weeknum - i ) 
      {
        datasets[3].data.push(thisstat.my_total_spending)
        datasets[2].data.push(thisstat.total_unified_spending)
        datasets[1].data.push(thisstat.i_paid)
        datasets[0].data.push(thisstat.i_recieved)
        labels.push(new Date(thisstat.time_bucket).toDateString().substring(4, 10));
        statindex++;
      } else {
        datasets[0].data.push(0);
        datasets[1].data.push(0);
        datasets[2].data.push(0);
        datasets[3].data.push(0);
        labels.push("KW"+String(weeknum-i))
      }
    }

    labels.reverse();
    datasets[0].data.reverse();
    datasets[1].data.reverse();
    datasets[2].data.reverse();
    datasets[3].data.reverse();
    is_ready = true;
  }

  return <ScrollView>
<LineChart
    data={{
      labels: is_ready ? labels : ["loading"],
      datasets: is_ready ? datasets : [{data:[0]}]
      
    }}
    width={Dimensions.get("window").width} // from react-native
    height={400}
    yAxisLabel="$"
    yAxisInterval={1} // optional, defaults to 1
    chartConfig={{
      backgroundColor: "#ffffff",
      backgroundGradientFrom: "#ffffff", 
      backgroundGradientTo: "#ffffff",
      propsForBackgroundLines: {
        stroke: "#ccc"
      },
      //backgroundGradientFrom: "#3d3d3d",
      //backgroundGradientTo: "#654977",
      decimalPlaces: 2, // optional, defaults to 2dp
      color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
      labelColor: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
      style: {
        borderRadius: 16
      },
      propsForDots: {
        r: "6",
        strokeWidth: "2",
        stroke: "#42006b"
      },
      useShadowColorFromDataset: true,
      fillShadowGradientFromOpacity: 0.4,
      fillShadowGradientToOpacity: 0
    }}
    bezier
    style={{
      marginVertical: 8,
      borderRadius: 16
    }}
  />
  <Text style={{backgroundColor: transparentWhite}}>Ausgaben der letzten 6 Wochen:{"\n"}Enthält bereits ausgeglichene Einkäufe{"\n"}
    <Text style={{color: "rgb(252, 132, 5)"}}>Orange</Text> für die Gesamte Wg{"\n"}
    <Text style={{color: "rgb(89, 78, 252)"}}>Blau</Text> für deine Ausgaben (Summe aller Anteile){"\n"}
    <Text style={{color: "#4F7B30"}}>Grün</Text> für was du zurückbekommst/kamst {"\n  "}(Für Einkäufe der Woche){"\n"}
    <Text style={{color: "rgb(252, 5, 5)"}}>Rot</Text> für was du den WGlern zahlst/zahltest{"\n  "}(Für Einkäufe der Woche){"\n"}
  </Text>
  <Text style={{backgroundColor: transparentWhite}}>{JSON.stringify(stats, undefined, 2)}</Text>
  </ScrollView>
}

const FinanceBalanceScreen = (props) => {
  var [balances] = useWebData("/api/my_wg/costs/balance", [], []);
  var [error, set_error] = useState(false);
  var [loading, setLoading] = useState(false);
  const [login, setLogin] = useContext(Identity);

  async function balanceOut() {
    setLoading(true);
    try {
      var res = await fetch(apiUrl + "/api/my_wg/costs/balance", { 
        method: "POST", 
        headers: { 'Content-Type': "application/json",
          "Authorization" : "Bearer "+login.token
      } });
      if ( res.ok ) {
        console.log( "New Balance Successful");
        if (props.rerenderHandle) props.rerenderHandle();
      } else {
        let text = await res.text();
        set_error(text);
      }
    } catch (err) {
      console.log(err);
      set_error(err);
    }
    setLoading(false);
  }

  //console.log("PROPS STATS", props)
  return (<ScrollView style={{flex:1}}>
    <Text style={{fontSize:23, alignSelf:"stretch", padding: 10, backgroundColor:transparentWhite,fontWeight:"bold",textAlign:"center"}}>Derzeitiger Unterschied</Text>
    {props.stats.map( (stat) => {
      let user = props.users[stat.user_id];
      //console.log("STAT", stat)
      return (<UserMoneyCard user={user} stat={stat} key={user.id}></UserMoneyCard>)
    })}
    <Button color={accentColor} disabled={loading} onPress={() => confirmationDialog(balanceOut)} title="Abrechnen/Null setzen"></Button>
    {error && <Text>{JSON.stringify(error, undefined, 2)}</Text>}
    <Text style={{fontSize:23, marginTop:20, alignSelf:"stretch", padding: 10, backgroundColor:transparentWhite,fontWeight:"bold",textAlign:"center"}}>Historische Abrechnungen</Text>
    {balances.map((bal) => {
      return <BalanceObject balance={bal} key={bal.id} navigation={props.navigation}/>
    })}
  </ScrollView>)
}

const BalanceObject = (props) => {
  let balance = props.balance;
  const {wg, users} = useContext(WgAndUsers);
  let user = users[String(balance.initiator_id)]||{};
  let date = new Date(balance.balanced_on);

  let i_paid = Number.parseFloat(balance.i_paid);
  let i_recieved = Number.parseFloat(balance.i_recieved);
  let my_total_spending = Number.parseFloat(balance.my_total_spending);
  let total_unified_spending = Number.parseFloat(balance.total_unified_spending);

  return (
    <Card imgUrl={urlFromUpload(user.profile_pic)} imgSize={40}
    title={<>
        <Text numberOfLines={1}>{date.toDateString()}</Text>
      </>}
    text={<View> 
        <Text>Angeordnet von {user.name}</Text>
        <View style={{flexDirection:"row", alignItems:"center"}}><Text>Ich gab </Text><Betrag amount={-my_total_spending} isWhite/><Text> aus.</Text></View>
        <View style={{flexDirection:"row", alignItems:"center"}}><Text>Die WG gab </Text><Betrag amount={-total_unified_spending} isWhite/><Text> aus.</Text></View>
      </View>}
    onPress={() => {
      if(props.navigation) props.navigation.push("HistoricBalanceCosts", {balanceId: balance.id})
    }}
    >
        <View style={{marginTop: 0, marginRight: 10}}><Text>total:</Text><Betrag amount={i_recieved-i_paid} isWhite/></View>
    </Card>
)
}

const FinaceCostsScreen = (props) => {
  let url = "/api/my_wg/costs";
  if(props.route && props.route.params && props.route.params.balanceId) { url += "?balance="+props.route.params.balanceId}

  var [costs] = useWebData(url, [], []);
  const {wg, users} = useContext(WgAndUsers);

  return(
  <><ScrollView style={{flex:1}}>
      {costs.map((cost ) => {
        return (<MoneyCard key={cost.id} cost={cost} users={users} onPress={() => {
          props.navigation.push("ViewCost", {cost, users})
        }}/>);
      })}
  </ScrollView>
  {props.canAddEntry && <View style={{backgroundColor: transparentWhite}}>
    <Pressable 
      style={({ pressed }) => ({
        backgroundColor: pressed?mutedlightAccentColor:accentColor, 
        paddingTop: pressed?10:5,
        paddingBottom: pressed?0:5,
        paddingStart: pressed?10:0,
        margin: 5, marginHorizontal: 15, 
        borderRadius: 10,
        borderWidth: 8,
        borderColor: pressed?mutedlightAccentColor:accentColor,
        borderTopColor: pressed?mutedAccentColor:lightAccentColor,
        borderStartColor: pressed?mutedAccentColor:lightAccentColor,
      })} 
      android_ripple={{color: "transparent"}}
      onPress={() => {
        props.navigation.push("NewEntry")
      }}
    >
      <Text style={{color: "white", textAlign: "center"}}>Neuer Eintrag</Text></Pressable>
  </View>}
  </>);
}

async function imageToBlob(pickerResult) {
  const blob = await new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      resolve(xhr.response);
    };
    xhr.onerror = function () {
      reject(new TypeError("Network request failed"));
    };
    xhr.responseType = "blob";
    xhr.open("GET", pickerResult.uri, true);
    xhr.send(null);
  });
  return blob
}

const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
const Base64 = {
  btoa: (input = '')  => {
    let str = input;
    let output = '';

    for (let block = 0, charCode, i = 0, map = chars;
    str.charAt(i | 0) || (map = '=', i % 1);
    output += map.charAt(63 & block >> 8 - i % 1 * 8)) {

      charCode = str.charCodeAt(i += 3/4);

      if (charCode > 0xFF) {
        throw new Error("'btoa' failed: The string to be encoded contains characters outside of the Latin1 range.");
      }
      
      block = block << 8 | charCode;
    }
    
    return output;
  },

  atob: (input= '') => {
    let str = input.replace(/=+$/, '');
    let output = '';

    if (str.length % 4 == 1) {
      throw new Error("'atob' failed: The string to be decoded is not correctly encoded.");
    }
    for (let bc = 0, bs = 0, buffer, i = 0;
      buffer = str.charAt(i++);

      ~buffer && (bs = bc % 4 ? bs * 64 + buffer : buffer,
        bc++ % 4) ? output += String.fromCharCode(255 & bs >> (-2 * bc & 6)) : 0
    ) {
      buffer = chars.indexOf(buffer);
    }

    return output;
  }
};

async function getAuthImage(uri, token) {
  let [buffer, ctype] = await new Promise((accept, reject) => {
    var req = new XMLHttpRequest();
    req.open("GET", uri, true);
    req.setRequestHeader("Authorization", "Bearer "+token);
    req.responseType = "arraybuffer";

    req.onload = function(event) {
        var resp = req.response;
        if(resp) {
            accept([resp,req.getResponseHeader('Content-Type')]);
        }
    };

    req.send(null);
  });

  var base64Flag = 'data:'+ctype+';base64,';

  var binary = '';
  var bytes = [].slice.call(new Uint8Array(buffer));

  bytes.forEach((b) => binary += String.fromCharCode(b));

  var imageStr = Base64.btoa(binary);

  return [base64Flag + imageStr, ctype];
}

const NewEntryScreen = (props) => {
  const {wg, users} = useContext(WgAndUsers);
  const [login, setLogin] = useContext(Identity);

  const [error, set_error] = useState("");
  const [loading, set_loading] = useState(0);

  const [name, setName] = useState("");
  const [amount, setAmount] = useState("0.00");
  const [selUsers, setSelUsers] = useState({});
  const [date, set_date] = useState(new Date());
  const [image, set_image] = useState(false);

  useEffect(()=> {
    let e = {};
    for (let user of Object.values(users) ) {
      e[user.id] = login.user.id === user.id;
    }
    setSelUsers(e);
  }, []);
  console.log(selUsers);

  // sanitize amount
  const onAmountChange = (amt) => {
    if( typeof amt !== "string" ) return;

    if( amt.match(/^[0123456789]*.*[0123456789]*$/) ) {
      amt = amt.replace(/[^0123456789]/g, "")
        .padStart(3, "0");
      //console.log("STEP 1 => "+amt)
      
      amt = amt.slice(0, amt.length-2) + "." + amt.slice(amt.length-2);
      //console.log("STEP 2 => "+amt)

      amt = amt.replace("0", " ").trimStart().replace(" ","0").padStart(4,"0");
      //console.log("STEP 3 => "+amt)

      setAmount(amt)
    } else {
      console.log("Ignored fool")
      setAmount("0.00")
    }
  };
  let amtf = Number.parseFloat(amount);
  let nr_shares = Object.keys(selUsers).length;
  let nr_othershares = nr_shares - (Object.keys(selUsers).includes(String(login.user.id)) ?1:0); 

  const onSubmit = async () => {
    set_loading(1);
    try {
      let payload = JSON.stringify( { name, amount: Number.parseFloat(amount), added_on: date.toISOString(), 
        debtors: Object.entries(selUsers).map(e=>[Number.parseInt(e[0]), e[1]]) } );
      console.log(payload);

      var res = await fetch(apiUrl + "/api/my_wg/costs", { 
        method: "POST", 
        body: payload, 
        headers: { 'Content-Type': "application/json",
          "Authorization" : "Bearer "+login.token
      } });
      if ( res.ok ) {
        let json = await res.json();
        console.log( "New Entry successfull", json );

        if( image ){
          try{
            set_loading(2);
            let filename = image.uri.split("?")[0].split("/").pop()||"lmao.jpg";
            // Infer the type of the image
            let match = /\.(\w+)$/.exec(filename);
            let type = match ? `image/${match[1]}` : `image`;

            const form = new FormData();
            if (Platform.OS === "web"){
              let blob =await imageToBlob(image);
              if ( filename.split(".").length < 2)
                filename += "." + blob.type.split("/").pop();
              form.append('receit',blob, filename);
            }else
              form.append('receit', {
                name: filename, type, uri: image.uri
              }, filename);
            
            var res = await fetch(apiUrl + "/api/my_wg/costs/"+json+"/receit", { 
              method: "PUT", 
              body: form, 
              headers: {
                "Authorization" : "Bearer "+login.token,
              }
            });
            if(!res.ok) {
              set_loading(0);
              let err = await res.text();
              console.log(err)
              set_error(err);
              return 
            }
          } catch(err) {
            set_loading(0);
            console.log(err);
            set_error(err);
            return;
          }
        }

        props.rerenderHandle();
        props.navigation.goBack();
        set_loading(0)
      } else {
        let text = await res.text();
        set_error(text);
        set_loading(0)
      }
    } catch (err) {
      set_error(err);
      set_loading(0);
    }
  }

  return (
    loading==2?<View style={{flex: 1, alignItems: "center", justifyContent: "center"}}>
      <Text>Eintrag eingerichtet: {"\n"} Versuche nun Ahhang hochzuladen... {"\n"} Einen Augenblick.. Fenster nicht schließen...
      {error&&"\nFEHLER: Naja verkackt- kann man nichts dran ändern, \n eintrag ist schon abgeschickt (schlechtes desin ik):\nSag mir mal bescheid wa:\n"+JSON.stringify(error)}
      </Text>
    </View>:
    (<ScrollView style={{margin: 10}} >
        <View style={{flexDirection: "row", alignItems: "center"}} >
          <TextInput style={{flex: 1, ...s.textfield}} placeholder='Beschreibung'  onChangeText={setName} value={name}></TextInput>
          <TextInput style={{minWidth: 50, marginLeft: 5, ...s.textfield}} placeholder='0.00' keyboardType='numeric' onChangeText={onAmountChange} value={amount}></TextInput>
          <Text>€</Text>
        </View>
        <DateComp onChange={set_date}/>
        <Text>Beteiligte:</Text>
        <View style={{flexDirection: "row", margin: 5, marginBottom: 20, flexWrap: 'wrap'}}>
          {Object.values(users).map((user) => {
            let selected = Object.keys(selUsers).includes(String(user.id));

            return (<Pressable key={user.id} style={{ flexDirection: "row", margin: 5, height: 60, minWidth: 108, alignItems: "center", justifyContent: "space-evenly" ,
            backgroundColor: selected?accentColor:"white" }}
              onPress={() => {
                setSelUsers((sel) => {
                  let ee = {...sel, [user.id]: user.id==login.user.id};
                  if (Object.keys(sel).includes(String(user.id))) delete ee[user.id];
                  return ee;
                })
              }}
            >
              <RoundImage imgUrl={urlFromUpload(user.profile_pic)} imgSize={50} styleRing={{borderWidth: 3, borderColor: "white"}}></RoundImage> 
              <Text style={{color:selected?"white":"black"}}>{user.name}</Text>
            </Pressable>);
          })}
        </View>

        <Text>Anhang:</Text>
        {image==false? (
          <View style={{justifyContent:"space-evenly", flexDirection:"row"}}>
            <Button title="Kamera" color={accentColor} onPress={async () => {
              let res = await ImagePicker.launchCameraAsync({quality: 1, exif: false, allowsEditing: true });
              if(!res.cancelled) set_image(res);
            }}/>
            <Button title="Bibliothek" color={accentColor} onPress={async () => {
              let res = await ImagePicker.launchImageLibraryAsync({quality: 1, exif: false, allowsEditing: true });
              if(!res.cancelled) set_image(res);
            }}/></View>
        ) : (<View><Button title="Entfernen" color="red" onPress={()=>set_image(false)}/><Image style={{flex:1, backgroundColor: "grey", minHeight: 400}} resizeMode="center" source={{uri: image.uri}}/></View>)}

        <View style={{borderBottomColor: 'black',borderBottomWidth: StyleSheet.hairlineWidth,}}/>
        <Text>Du hast {amount}€ bezahlt.</Text>
        <Text>Du bekommst <Betrag amount={amtf/nr_shares*(nr_othershares)}></Betrag> von den {nr_othershares} anderen Beteiligten zurück</Text>
        <View style={{borderBottomColor: 'black',borderBottomWidth: StyleSheet.hairlineWidth, marginBottom:20}}/>
        <Button title="Senden" color={accentColor} disabled={loading!=0||!name||(!amtf>0)||nr_shares<1} onPress={onSubmit}></Button>
        <Text>{JSON.stringify(error)}</Text>
    </ScrollView>)
  );
}

const CostDetailScreen = (props) => {
  let cost = props.route.params.cost;
  const [login, setLogin] = useContext(Identity);
  let {my_gain, single_payment, amt, am_creditor} = interpret_cost_object(login.user.id, cost);

  let users = props.route.params.users;
  let [shares] = useWebData("/api/my_wg/costs/"+cost.id+"/shares", [], false);
  let date = new Date(cost.added_on).toString();

  

  let [[blob, ctype], setBlob] = useState([null,null]);
  useEffect( () => {
    let blobPromise = getAuthImage(urlFromUpload(cost.receit), login.token);
    blobPromise.then((e) => {
        console.log("Blob ready!")
        //console.log(e);
        setBlob(e)
    }, (err) => {
      console.error("Couldn't get image blob:", err);
      setBlob([{error: true}, null])
    });
  }, [cost.receit, login])

  const onPressImg = async () => {
    if( blob && blob.error!==false ) {
      if(await Sharing.isAvailableAsync()) {
        try {

          await Sharing.shareAsync(blob, {dialogTitle: "Share Receit", mimeType: ctype })
        } catch (err) {
          console.error("Couldn't share: ",err)
        }
      } else {
        console.error("Sharing Unavailable")
      }
    } else {
      console.error("Blob nicht am start!")
    }
  };

  const deleteEntry = () => {
    console.log("Delete thing");
    const res = fetch(apiUrl + "/api/my_wg/" + cost.id, { 
      method: "DELETE", 
      headers: {
        "Authorization" : "Bearer "+login.token,
      }
    });
    res.then(() => {

    }).catch(() => {
      
    });
  };

  //console.log(urlFromUpload(cost.reciet));

  let receitSource = {headers: { "Authorization": "Bearer "+login.token, "Accept": "*/*", "Accept-Encoding": "gzip, deflate, br" }, method: "GET", uri: urlFromUpload(cost.receit)};
  console.log(receitSource);

  return <ScrollView><MoneyCard key={cost.id} cost={cost} users={users}/><View style={{margin: 10}}>
    
    <Text>Datum: {date}</Text>
    <Text style={{fontSize:20, marginTop:20, alignSelf:"stretch", backgroundColor:transparentWhite,fontWeight:"bold",textAlign:"center"}}>Anteile:</Text>
    {shares.map((share) => {
      let user = users[String(share.debtor_id)] || {name: "????Ubkenannter Nuter?????"};

      let ret;
      let ts=share.paid?{textDecorationLine:"line-through", color: "grey"}:{};
      
      if( cost.my_share.debtor_id === share.debtor_id ) {
        ret=<><Text style={ts}>{user.name} übernimmt </Text><Betrag amount={-single_payment} strikethrough={share.paid}/></>
      }else if( am_creditor) {
        ret=<><Text style={ts}>{user.name} übernimmt </Text><Betrag amount={single_payment} strikethrough={share.paid}/></>
      } else {
        ret=<><Text style={ts}>{user.name} übernimmt </Text><Betrag amount={single_payment} strikethrough={true}/></>
      }

      return <View key={share.debtor_id} style={{flexDirection:"row", alignItems:"center"}}>{ret}{share.paid&&<Text> ✔gezahlt</Text>}</View>
    })}
    <View style={{flexDirection:"row", alignItems:"center"}}><Text>Du bekommst/zahlst noch: </Text><Betrag amount={my_gain}/></View>
    {cost.receit && cost.receit.id && <>
      <Text style={{fontSize:20, marginTop:20, alignSelf:"stretch", backgroundColor:transparentWhite,fontWeight:"bold",textAlign:"center"}}>Beleg:</Text>
      <Pressable /*onPress={onPressImg}*/>
        <Image style={{flex:1, backgroundColor: blob&&blob.error===true?"red":"lightgray", minHeight: 600}} 
        resizeMode="center" 
        loadingIndicatorSource={require("./loadbar.png")}
        source={blob==null?require("./loadbar.png"):blob.error===true?{uri: urlFromUpload({})}:{uri: blob}}
        onError={(error) => console.error("Failed loading image: ", error.nativeEvent, error.nativeEvent.error)}
        onLoad={()=>console.log("Image pulled successfully.")}
        />
      </Pressable>
      
    </>}
    {/*<Button color="red" onPress={() => confirmationDialog(deleteEntry)} title="Löschen."></Button>*/}
  </View></ScrollView>
}

const s = StyleSheet.create({
  textfield: { 
    borderColor: lightAccentColor, 
    borderWidth: 2,
    borderRadius: 10, 
    padding: 4,
    backgroundColor: "white"
  }
})


export { FinanceScreen }