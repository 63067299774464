import { StatusBar } from 'expo-status-bar';
import { Button, ImageBackground, ScrollView, StyleSheet, Text, TextInput, View, Pressable, Image } from 'react-native';
import React, { useContext, useEffect, useState } from 'react';
import { NavigationContainer, useNavigation } from '@react-navigation/native';
import Icon from 'react-native-vector-icons/FontAwesome';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createStackNavigator } from '@react-navigation/stack';


import { StoreScreen } from './wg';
import { Betrag, MoneyCard, RoundImage, SimpleCard, UserMoneyCard } from './components';
import { accentColor, apiUrl, Identity, lightAccentColor, mutedAccentColor, mutedlightAccentColor, transparentWhite, urlFromUpload, WgAndUsers } from './constants';
import { useWebData } from './hooks';

import AsyncStorage from '@react-native-async-storage/async-storage';
import { FinanceScreen } from './finance';

import * as ImagePicker from 'expo-image-picker';

const Tab = createBottomTabNavigator();
/*
export function App() {
  return <View>
  <Image style={{flex:1, backgroundColor: "gray", minHeight: 600}} 
  resizeMode="center" 
  source={
    {headers: { 
      "Authorization": "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdXRoX2FzIjoyLCJhdWQiOiJVU0VSIiwiZXhwIjoxNjk5MTY3NzEyLCJpYXQiOjE2Njc2MTAxMTIsImlzcyI6IndnLWFwaSB2MC4xLjAgYnkgS2FydG9ucmFkIDxoaUBrYXJ0b25yYWQuZGU-In0.7CkNG7wywPfk_Lk6FCYRYBmae4j44jmp1UypPwDs-Rw",
    }, 
    uri: apiUrl+"/uploads/11.jpeg"}}
  /></View>
}*/

export function App() {
  const [initialized, set_initialized] = useState(false);
  const [login, setLogin] = useState(false);

  console.log("APP LVL RD: ", initialized, login);

  function ssetLogin(e) {
    //console.log(e);
    setLogin((oldLogin) => {
      let wert = e;
      if(typeof e=="function") {
        console.log("Lmao function?")
        wert = e(oldLogin);
      }
      saveData(wert);
      return wert;
    });
  }

  async function saveData(d) {
    try {
      const jsonValue = JSON.stringify(d)
      await AsyncStorage.setItem('@login_data', jsonValue);
      console.log("Login Value saved!")
    } catch (e) {
      console.error("Error saving value in AsyncStorage: ", e)
    }
  }

  const load = async () => {
    try {
      const jsonValue = await AsyncStorage.getItem('@login_data')
      let wert =  jsonValue != null ? JSON.parse(jsonValue) : null;
      if( typeof wert == "object" && wert !== null) {
        console.log("Successfully fetched login val!");
        setLogin(wert);
      } else {
        console.log("Successfully fetched the realization that user is logged out!");
        set_initialized(true); 
      }

    } catch(e) {
      // error reading value
      console.error("Error getting login val: ",e)
      set_initialized(true); // ohne login wert reinzugeben
      return;
    }
  }

  useEffect(() => {
    if(login && !initialized) {
      set_initialized(true)
    }
  }, [login,initialized])


  useEffect(() => {
    load()
  }, []);

  if( !initialized ) {
    
    return <View style={{flex: 1, alignItems: "center", justifyContent:"center"}}><Text>Lädt login token...</Text></View>
  }

  return (
    
    <Identity.Provider value={[login, ssetLogin]}>
      <NavigationContainer>
        { login!=false ? ( 
          <Main/>
        ): (
          <LoginFlow/>
        )}
      </NavigationContainer>
    </Identity.Provider>
  );
}

const LoginFlowStack = createStackNavigator();

function LoginFlow() {
  return (
    <LoginFlowStack.Navigator initialRouteName='Start' screenOptions={{header: ()=>{return false} }}>
      <LoginFlowStack.Screen name='Start' component={LoginFlowStart}></LoginFlowStack.Screen>
      <LoginFlowStack.Screen name='ChooseWGUser' component={LoginFlowChooseWGUser}></LoginFlowStack.Screen>
      <LoginFlowStack.Screen name='AsUser' component={LoginFlowAsUser}></LoginFlowStack.Screen>
    </LoginFlowStack.Navigator>
  );
}

function LoginFlowStart({navigation}) {
  const [wg_url, set_wg_url] = useState("");
  console.log(wg_url);

  return (
    <View style={s.container}>
      <Text style={{fontSize: 32}}>Hallo!</Text>
      <Text>Gebe das Kürzel deiner WG an...</Text>
      <TextInput placeholder='z.b. wg-von-heinz' style={s.input} value={wg_url} onChangeText={set_wg_url}></TextInput>
      <Button title='Anmelden' onPress={() => navigation.push("ChooseWGUser", {wg_url})}></Button>
    </View>);
}

function LoginFlowAsUser (props) {
  const [psswd, set_psswd] = useState("");
  const [loading, set_loading] = useState(false);
  const [error, set_error] = useState(null);
  let user = props.route.params.user || {};

  const [login, setLogin] = useContext(Identity);

  return (
    <View style={s.container}>
      <RoundImage imgSize={200} style={s.headerImg} styleRing={{borderColor: "afb1cc", borderWidth: 7}} imgUrl={urlFromUpload(user.profile_pic)}></RoundImage>
      <Text style={{fontSize: 32}}>{user.name}<Text style={{color: "rgba(0, 0, 0, 0.48)"}}>- @{user.username}</Text> </Text>
      <Text>{error?("AHA! UNfug also? (Gab fehler:)\n"+error):"NA fug?! stimmt das denn auch? Gib mal Passwort ran jetzt"}</Text>
      <TextInput placeholder='darkest secret (i mean prlly one of the lightest actually)' style={s.input} value={psswd} onChangeText={set_psswd}></TextInput>
      <Button disabled={loading} title='Anmelden' onPress={async (ev) => {
        set_loading(true);
        //console.log("LOGIN", user, psswd)
        try {
          var res = await fetch(apiUrl + "/auth/login", { 
            method: "POST", 
            body: JSON.stringify( { username: user.username, password: psswd } ), 
            headers: { 'Content-Type': "application/json" } });
          if ( res.ok ) {
            let json = await res.json();
            console.log( "LOGIN successful", json );



            setLogin({ ...json, user})
          } else {
            let text = await res.text();
            set_error(text);
          }
        } catch (err) {
          set_error(err);
        }
        set_loading(false);
      }}></Button>
    </View>);
}

function LoginFlowChooseWGUser(props) {
  return <StoreScreen onUserPress={(user) => {
    props.navigation.push("AsUser", {user});
  }} {...props}/>
}

function Main() {
  const [wg] = useWebData("/api/my_wg", {
        name: "WG",
        description: "Loading...",
        profile_pic: null,
        header_pic: null
    }, {
        name: "FEHLER!!",
        description: "Konnte auf diese WG nicht zugreifen!"
    });

    const [users] = useWebData("/api/my_wg/users", [], [{
        name: "FEHLER!",
        username: "Halt",
        id: -1
    }]);

    let userDict = {};
    users.forEach((user) =>{
      userDict[user.id] = user;
    })

  return (
    <WgAndUsers.Provider value={{wg, users: userDict}}>
      <Tab.Navigator screenOptions={{
        tabBarShowLabel: false
      }}>
          <Tab.Screen name="Home" component={HomeScreen} options={{
            tabBarIcon: ({focused: f}) => (<Icon name="home" size={30} color={f? accentColor : mutedAccentColor} />),
            headerShown: false
          }}/>
          <Tab.Screen name="Finance" component={FinanceScreen} options={{
            tabBarIcon: ({focused: f}) => (<Icon name="money" size={30} color={f? accentColor : mutedAccentColor} />),
            headerShown: false
          }}/>
          <Tab.Screen name="Setting" component={SettingScreen} options={{
            tabBarIcon: ({focused: f}) => (<Icon name="gears" size={30} color={f? accentColor : mutedAccentColor} />)
          }} />
      </Tab.Navigator>
    </WgAndUsers.Provider>
  );
}

const s = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  input: {
    borderColor: '#757575',
    borderRadius: 10,
    borderWidth: 2,
    padding: 5,
    alignSelf: 'stretch',
    margin: 20
  }
});


const HomeScreen = (props ) => {
  return <StoreScreen {...props}></StoreScreen>
}

function ImagePickerrrr(props) {
  const [image, setImage] = useState(null);

  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: props.aspect || [1,1],
      quality: 1,
    });

    console.log(result);

    if (!result.cancelled && props.setImage) {
      props.setImage(result.uri);
    }
  };

  return (
    <Pressable onPress={pickImage}>
      {props.children}
    </Pressable>
  );
}

const SettingScreen = (props) => {
  const [login, setLogin] = useContext(Identity);

  return <View><Text>Settings</Text>
    {/*<UserForm></UserForm>

    <Text style={{fontSize:20, marginTop:20, alignSelf:"stretch", backgroundColor:transparentWhite,fontWeight:"bold",textAlign:"center"}}>WG</Text>
    <TextInput placeholder="WG Name"/>
    <TextInput placeholder="/url"/>
    <TextInput multiline={true} placeholder="Beschreibung"/>
    <Text>Header:</Text>
    <ImagePickerrrr/>
    <Text>Profile_pic:</Text>
    <ImagePickerrrr/>*/}

    <Button title="ABMELDEN" color="red" onPress={()=>{
      setLogin(false)
    }}></Button>
  </View>
}

const UserForm = (props) => {
  let [login] = useContext(Identity)
  let [ppic, setPpic] = useState(urlFromUpload(login.user.profile_pic));

  return <View>
    <Text style={{fontSize:20, marginTop:20, alignSelf:"stretch", backgroundColor:transparentWhite,fontWeight:"bold",textAlign:"center"}}>User</Text>
    <TextInput placeholder={login.user.name}/>
    <TextInput placeholder={login.user.username}/>
    <TextInput multiline={true} placeholder={login.user.bio}/>
    <Text>Profile_pic:</Text>
    <ImagePickerrrr setImage={setPpic}>
      <RoundImage imgSize={300} imgUrl={ppic}></RoundImage>
    </ImagePickerrrr>
  </View>
}