import React from 'react';
import { apiUrl, Identity } from './constants.js';

export function useWebData(path, initial, error_obj) {
    const [data, setData] = React.useState(initial);
    const [identity] = React.useContext(Identity);

    React.useEffect(() => {
        async function hs() {
            let reqInit = {};
            if(identity) {
                reqInit.headers = {
                    "Authorization" : "Bearer "+identity.token
                };
            }

            try {
                console.log("Fetching "+path);
                var res = await fetch(apiUrl + path, reqInit);
                if(!res.ok) {
                    if (typeof error_obj !== "undefined")  {
                        setData(error_obj);
                    }
                    console.error("Failed fetch ["+path+"]: TEXT=> "+await res.text());
                    return;
                }

                var json = await res.json();
                if (typeof initial === typeof json) {
                    setData(json);
                    //console.log(json);
                }
            } catch (err) {
                console.log(err);
                if (typeof error_obj !== "undefined")  {
                    setData(error_obj);
                }
            }
        } if(path) hs();
    }, [path])

    return [data];
}
